"use client";

import React, { useEffect } from "react";
// Eliminamos la importación de SessionProvider
// import { SessionProvider } from "next-auth/react";
import { getUserInfo, isUserLoggedIn, handleLogout } from "./Auth";

const Providers = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  useEffect(() => {
    try {
      if (isUserLoggedIn()) {
        getUserInfo();
      }
    } catch (error) {
      handleLogout();
    }
  }, []);

  // Eliminamos el SessionProvider y devolvemos directamente los children
  return <>{children}</>;
};

export default Providers;
