"use client";

import axios from "axios";
import useAuthStore from "./store/authStore";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useRouter } from 'next/navigation';

// Verifica si el usuario está logueado verificando cookies
export const isUserLoggedIn = () => {
  const token = Cookies.get("t_u");
  const userId = Cookies.get("u_i_d");
  const rol = Cookies.get("r_u");
  return !!(token && userId && rol);
};

// Retorna el tipo de usuario según el rol
const getUserType = (rol: string) => {
  switch (rol) {
    case "ROLE_ESCUELA":
      return "escuela";
    case "ROLE_INSTRUCTOR":
      return "instructor";
    case "ROLE_ADMIN":
      return "admin";
    case "ROLE_USER":
    default:
      return "usuario";
  }
};

export const getToken = async () => {
  const token = Cookies.get("t_u");
  return token
}

export const getRol = async () => {
  const rol = Cookies.get("r_u");
  return rol
}

export const getId = async () => {
  const userId = Cookies.get("u_i_d");
  return userId
}

export const getUserInfo = async () => {
  // Obtener valores actualizados de las cookies
  const currentToken = Cookies.get("t_u");
  const currentUserId = Cookies.get("u_i_d"); 
  const currentRol = Cookies.get("r_u");

  if (!currentToken || !currentUserId || !currentRol) {
    return null; // No hay usuario logueado
  }

  const userType = getUserType(currentRol);
  const finalUserType = currentRol === "ROLE_ADMIN" ? "usuario" : userType;
  const baseUrl = process.env.NEXT_PUBLIC_API_URL ;
  const headers = { Authorization: `Bearer ${currentToken}` };

  try {
    const endpoint =
      userType === "instructor"
        ? `/instructor/perfil/${finalUserType}/${currentUserId}`
        : `/perfil/${finalUserType}/${currentUserId}`;

    const response = await axios.get(`${baseUrl}${endpoint}`, { headers });

    if (currentRol === "ROLE_ADMIN") {
      const authStore = useAuthStore.getState();
      authStore.setAuthData({
        userData: {
          aceptoTerminosYcondiciones: response.data.aceptoTerminosYcondiciones,
          calificaciones: response.data.calificaciones,
          cantidadProductosPermitidos:
            response.data.cantidadProductosPermitidos,
          esNuevo: response.data.esNuevo,
          facebookId: response.data.facebookId,
          habilitado: response.data.habilitado,
          macId: response.data.macId,
          nombre: response.data.nombre,
          profilePic: response.data.profilePic,
          subscripcionId: response.data.subscripcionId,
          totalCalificaciones: response.data.totalCalificaciones,
          username: response.data.username,
          usuarioId: response.data.usuarioId,
        },
        rol: currentRol,
        jwttoken: currentToken,
        token: currentToken,
      });
    }

    return response.data;
  } catch (error) {
    console.error("Error al obtener la información del usuario:", error);
    /* handleLogout(); */
    return null;
  }
};

// Maneja el inicio de sesión del usuario
export const handleSignIn = async (
  username: string,
  password: string,
  setIsLoading: (loading: boolean) => void,
  router: any
) => {
  if (!username.trim() || !password.trim()) {
    toast.error("Todos los campos son obligatorios");
    return;
  }

  setIsLoading(true);

  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/authenticate`,
      { username, password }
    );

    if (
      response.status === 200 &&
      response.data?.jwttoken &&
      response.data?.rol
    ) {
      // Guardar token y usuarioId en cookies
      Cookies.set("t_u", response.data.jwttoken, {
        path: "/", // La cookie estará disponible en toda la aplicación
        secure: true, // Solo permite que la cookie se envíe a través de HTTPS
        sameSite: "Strict", // Evita el envío de cookies a otros dominios (CSRF)
        expires: 1, // Opcional: la cookie expira después de 1 día
      });

      Cookies.set("u_i_d", response.data.usuarioId, {
        path: "/",
        secure: true,
        sameSite: "Strict",
        expires: 1,
      });

      Cookies.set("r_u", response.data.rol, {
        path: "/",
        secure: true,
        sameSite: "Strict",
        expires: 1,
      });

      // Actualizar el estado global del store
      const authStore = useAuthStore.getState();
      authStore.setAuthData({
        userData: {
          aceptoTerminosYcondiciones: response.data.aceptoTerminosYcondiciones,
          calificaciones: response.data.calificaciones,
          cantidadProductosPermitidos:
            response.data.cantidadProductosPermitidos,
          esNuevo: response.data.esNuevo,
          facebookId: response.data.facebookId,
          habilitado: response.data.habilitado,
          macId: response.data.macId,
          nombre: response.data.nombre,
          profilePic: response.data.profilePic,
          subscripcionId: response.data.subscripcionId,
          totalCalificaciones: response.data.totalCalificaciones,
          username: response.data.username,
          usuarioId: response.data.usuarioId,
        },
        jwttoken: response.data.jwttoken,
        token: response.data.jwttoken,
        rol: response.data.rol,
      });

      toast.success("¡Inicio de sesión exitoso!");

      router.push(`/`);
    } else {
      throw new Error("La respuesta no contiene los datos esperados");
    }
  } catch (error) {
    console.error("Error en signin:", error);

    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 401) {
        toast.error("Usuario o contraseña incorrectos");
      } else {
        toast.error(
          error.response.data.message ||
            "Error al iniciar sesión. Intente nuevamente."
        );
      }
    } else {
      toast.error("Error al iniciar sesión. Intente nuevamente.");
    }
  } finally {
    setIsLoading(false);
  }
};

// Maneja el cierre de sesión del usuario
export const handleLogout = async () => {
  // Elimina las cookies
  Cookies.remove("t_u");
  Cookies.remove("u_i_d");
  Cookies.remove("r_u");

  // Resetea el estado del usuario en el store
  const authStore = useAuthStore.getState();
  authStore.setAuthData({
    userData: {
      aceptoTerminosYcondiciones: false,
      calificaciones: 0,
      cantidadProductosPermitidos: 0,
      esNuevo: false,
      facebookId: null,
      habilitado: false,
      macId: null,
      nombre: "",
      profilePic: null,
      subscripcionId: null,
      totalCalificaciones: 0,
      username: "",
      usuarioId: 0,
    },
    rol: "",
    jwttoken: null,
    token: null,
  });

  // Redirige al usuario
  window.location.href = "/";
};

