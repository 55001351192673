"use client";

import { useEffect, useState, useRef } from "react";
import Link from "next/link";
import Button from "../Button/Button";
import UserProfileButton from "./MenuUser/MenuUser";
import { getRol, isUserLoggedIn, handleLogout } from "@/lib/Auth";
import { Skeleton } from "@/components/ui/skeleton";
import useMobile from "@/lib/hooks/UseMobile";
import { Menu, X } from "lucide-react";

const Nav = () => {
  const [userConnected, setUserConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [authKey, setAuthKey] = useState(0);
  const isMobile = useMobile();
  const [userRole, setUserRole] = useState(false);

  useEffect(() => {
    const checkRole = async () => {
      const rol = await getRol();
      if (rol === "ROLE_ADMIN") {
        setUserRole(true);
      }
    };
    checkRole();
  }, []);

  const handleSignOut = async () => {
    await handleLogout();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const userConnectedRef = useRef(userConnected);

  useEffect(() => {
    const checkSession = () => {
      const isLoggedIn = isUserLoggedIn();
      setUserConnected(isLoggedIn);
      userConnectedRef.current = isLoggedIn;
      setIsLoading(false);
    };

    checkSession();

    // Configurar intervalo para verificar el estado de autenticación
    const authCheckInterval = setInterval(() => {
      const currentAuthState = isUserLoggedIn();
      if (currentAuthState !== userConnectedRef.current) {
        setUserConnected(currentAuthState);
        userConnectedRef.current = currentAuthState; // Actualizar referencia
        setAuthKey((prev) => prev + 1); // Incrementar key para forzar re-render si es necesario
      }
    }, 2000); // Verificar cada 5 segundos

    return () => clearInterval(authCheckInterval);
  }, []);

  return (
    <div
      key={authKey}
      className="transition-all animate-in fade-in-0 duration-300 min-h-[4.4rem]"
    >
      <header
        className={`fixed min-h-[3rem] sm:px-3 2md:px-[2rem] w-full  py-1 backdrop-blur-lg transition-all animate-in fade-in-0 duration-300 !rounded-tl-0 shadow-lg shadow-[#f2f3ff0a] !z-[9999999]`}
        style={{
          backgroundImage:
            "-webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #000b47), color-stop(100%, #000427))",
        }}
      >
        <div className="px-4">
          <div className="flex items-center justify-between">
            <div className="flex shrink-0">
              <Link
                aria-current="page"
                className="flex items-center min-h-[2.4rem]"
                href="/"
              >
                <img
                  src="/images/logo/Logo.webp"
                  alt="logo"
                  className="w-[150px] h-auto 2md:w-[160px] flex"
                  width={150}
                  height={40}
                />
              </Link>
            </div>

            {isMobile ? (
              <>
                <button
                  onClick={toggleMenu}
                  className="p-2 text-[#fdfdfd]"
                  aria-label="Toggle menu"
                >
                  {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>

                {isMenuOpen && (
                  <div
                    style={{
                      backgroundImage:
                        "-webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #000b47), color-stop(100%, #000427))",
                    }}
                    className="absolute top-full left-0 right-0 shadow-lg py-4 px-6 flex flex-col gap-4 min-h-[94dvh] pt-20 transition-all animate-in fade-in-0 duration-300"
                  >
                    {isLoading ? (
                      <Skeleton className="h-9 w-full" />
                    ) : userConnected ? (
                      <div className="flex flex-col gap-2 absolute top-3 right-0 px-4">
                        <UserProfileButton handleSignOut={handleSignOut} toggleMenu={toggleMenu} />
                      </div>
                    ) : (
                      <div className="flex flex-col gap-3 w-full absolute bottom-16 left-0 px-4">
                        <Link href="/login" onClick={toggleMenu}>
                          <Button
                            title="Iniciar Sesión"
                            className="w-full text-center flex justify-center items-center"
                          />
                        </Link>
                        <Link href="/signUp" onClick={toggleMenu}>
                          <Button
                            title="Registrate"
                            variant="sub"
                            className="!text-[#fefefe] w-full text-center flex justify-center items-center"
                          />
                        </Link>
                      </div>
                    )}

                    <Link
                      className="text-[20px] font-bold text-[#fefefe]"
                      href="/"
                      onClick={toggleMenu}
                    >
                      Inicio
                    </Link>
                    <hr className="border-[#e9eeff41]" />
                    <Link
                      className="text-[20px] font-bold text-[#fefefe]"
                      href="/clases"
                      onClick={toggleMenu}
                    >
                      Clases
                    </Link>
                    <hr className="border-[#e9eeff41]" />

                    {/*  <Link
                      className="text-[20px] font-bold text-[#fefefe]"
                      href="/spots"
                      onClick={toggleMenu}
                    >
                      Spots
                    </Link>
                    <hr className="border-[#e9eeff41]" /> */}

                    <Link
                      className="text-[20px] font-bold text-[#fefefe]"
                      href="/help"
                      onClick={toggleMenu}
                    >
                      Ayuda
                    </Link>
                    <hr className="border-[#e9eeff41]" />
                    {userConnected && !userRole && (
                      <>
                        <Link
                          className="text-[20px] font-bold text-[#fefefe]"
                          href="/misClases"
                          onClick={toggleMenu}
                        >
                          <button></button>
                          Mis clases
                        </Link>
                        <hr className="border-[#e9eeff41]" />
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="hidden 2md:flex 2md:items-center 2md:justify-center 2md:gap-0 ">
                <Link
                  aria-current="page"
                  className="inline-block rounded-lg px-2 py-1 text-[16px] font-bold text-[#fdfdfd] transition-all duration-200"
                  href="/"
                >
                  Inicio
                </Link>
                <Link
                  className="inline-block rounded-lg px-2 py-1 text-[16px] font-bold text-[#fdfdfd] transition-all duration-200"
                  href="/clases"
                >
                  Clases
                </Link>
                {/*     <Link
                  className="inline-block rounded-lg px-2 py-1 text-[16px] font-bold text-[#fdfdfd] transition-all duration-200"
                  href="/spots"
                >
                  Spots
                </Link> */}
                <Link
                  className="inline-block rounded-lg px-2 py-1 text-[16px] font-bold text-[#fdfdfd] transition-all duration-200"
                  href="/help"
                >
                  Ayuda
                </Link>

                {userConnected && !userRole && (
                  <Link
                    className="inline-block rounded-lg px-2 py-1 text-[16px] font-bold text-[#fdfdfd] transition-all duration-200"
                    href="/misClases"
                    onClick={toggleMenu}
                  >
                    <button></button>
                    Mis clases
                  </Link>
                )}

                {isLoading ? (
                  <div className="flex gap-4">
                    <Skeleton className="h-9 w-[10rem] px-10 " />
                  </div>
                ) : userConnected ? (
                  <>
                    <UserProfileButton handleSignOut={handleSignOut} toggleMenu={toggleMenu} />
                  </>
                ) : (
                  <>
                    <Link
                      className="inline-block rounded-lg px-4 py-1 text-[16px] font-medium text-[#fefefe] transition-all duration-200 hover:font-bold"
                      href="/login"
                    >
                      <Button title="Iniciar Sesión" className="" />
                    </Link>

                    <Link
                      className="inline-block rounded-lg  py-1 text-[16px] font-bold text-[#fefefe] transition-all duration-200"
                      href="/signUp"
                    >
                      <Button
                        title="Registrate"
                        variant="sub"
                        className="!text-[#ffffff]"
                      />
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Nav;
