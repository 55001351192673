import { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { User, LogOut } from "lucide-react";
import Link from "next/link";
import useAuthStore from "@/lib/store/authStore";
import { handleLogout, getUserInfo, getRol } from "@/lib/Auth";

interface UserProfileButtonProps {
  handleSignOut: () => void;
  toggleMenu: () => void;
}

export default function UserProfileButton({
  handleSignOut,
  toggleMenu,
}: UserProfileButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const { userData } = useAuthStore();
  const [userRole, setUserRole] = useState<string>("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = await getUserInfo();
      if (info) {
        setUserInfo(info);
      }
    };
    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      const rol = await getRol();
      if (rol) setUserRole(rol);
    };
    fetchRole();
  }, []);

  const handleCerrarSesion = () => {
    handleSignOut();
    handleLogout();
    setIsOpen(false);
  };

  const getProfilePath = () => {
    switch (userRole) {
      case "ROLE_ESCUELA":
        return "/perfil";
      case "ROLE_INSTRUCTOR":
        return "/perfil";
      case "ROLE_ADMIN":
        return "/dashboard";
      default:
        return "/perfil";
    }
  };

  return (
    <div className="px-4 cursor-pointer ">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger
          asChild
          className="bg-[#fcfdff] shadow-lg !z-[999999999]"
          aria-label="Menú de perfil del usuario"
        >
          <div className="flex items-center space-x-2 px-3 py-1 rounded-full hover:bg-accent">
            <Avatar className="h-8 w-8 shadow-xl">
              <AvatarImage
                src={
                  userInfo?.profilePic ||
                  userData.profilePic ||
                  "/placeholder-avatar.jpg"
                }
                alt="Avatar de usuario"
              />
              <AvatarFallback>
                <User className="h-4 w-4" />
              </AvatarFallback>
            </Avatar>
            <span className="font-medium">
              {userInfo?.nombre || userData.nombre || "Usuario"}
            </span>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`transform transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
            >
              <path
                d="M2.25 4.5L6 8.25L9.75 4.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="w-48 h-[7rem] flex justify-start items-start flex-col mt-[0rem] relative !z-[9999999]"
        >
          <DropdownMenuItem asChild>
            <Link
              href={getProfilePath()}
              onClick={toggleMenu}
              className="h-full w-full  flex items-center cursor-pointer hover:bg-[#c3e4ff3b]"
            >
              <User className="mr-2 h-4 w-4" />
              {userRole === "ROLE_ADMIN" ? (
                <span>Dashboard</span>
              ) : (
                <span>Perfil</span>
              )}
            </Link>
          </DropdownMenuItem>
          <hr className="w-full text-black" />
          <DropdownMenuItem
            className="h-full w-full mt-0 pt-0 flex items-center text-red-600 cursor-pointer hover:bg-[#ffc3c33b]"
            onClick={handleCerrarSesion}
          >
            <LogOut className="mr-2 h-4 w-4" />
            <span onClick={toggleMenu}>Cerrar sesión</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
