import { create } from "zustand";

interface UserData {
  aceptoTerminosYcondiciones: boolean;
  calificaciones: number;
  cantidadProductosPermitidos: number;
  esNuevo: boolean;
  facebookId: string | null;
  habilitado: boolean;
  macId: string | null;
  nombre: string;
  profilePic: string | null;
  subscripcionId: string | null;
  totalCalificaciones: number;
  username: string;
  usuarioId: number;
}

interface AuthState {
  userData: UserData;
  rol: string;
  jwttoken: string | null;
  token: string | null;
  setAuthData: (data: Partial<AuthState>) => void;
}

const useAuthStore = create<AuthState>((set) => ({
  userData: {
    aceptoTerminosYcondiciones: false,
    calificaciones: 0,
    cantidadProductosPermitidos: 0,
    esNuevo: false,
    facebookId: null,
    habilitado: false,
    macId: null,
    nombre: "",
    profilePic: null,
    subscripcionId: null,
    totalCalificaciones: 0,
    username: "",
    usuarioId: 0,
  },
  rol: "",
  jwttoken: null,
  token: null,
  setAuthData: (data) => set((state) => ({ ...state, ...data })),
}));

export default useAuthStore;
